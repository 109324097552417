import React from 'react';
import './App.css';
import mondaySdk from 'monday-sdk-js';
import 'monday-ui-react-core/dist/main.css';
import {
  Flex,
  Loader,
  Checkbox,
  Button,
  TextField,
} from 'monday-ui-react-core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { AddNewDoc, Retry } from 'monday-ui-react-core/icons';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

const Backend = require('./Backend');
const monday = mondaySdk();

class MainForm extends React.Component {
  constructor(props) {
    super(props);

    // Default state
    this.state = {
      context: {},
      loading: true,
      forms: null,
      form: null,
      sessionToken: null,
      addingNewForm: false,
      formStatusChanging: false,
      formDeleting: false,
      formSaving: false,
      formRefreshing: false,
      submitStatusChangeField: null,
      submitStatusChangeFieldValue: null,
      deactivationStatusField: null,
      deactivationStatusFieldValue: null,
    };
  }

  componentDidMount() {
    monday.listen('context', this.getContext);
  }

  async getSessionToken() {
    let res = await monday.get('sessionToken');
    this.setState({ sessionToken: res.data });
    return res.data;
  }

  async getForms() {
    let sessionTokem = this.state.sessionToken;
    if (sessionTokem == null) {
      sessionTokem = await this.getSessionToken();
    }
    Backend.getForms(sessionTokem, this.state.boardId).then((forms) => {
      this.setState({ loading: false, forms: forms });
    });
  }

  addNewForm = () => {
    this.setState({ addingNewForm: true });
    let sessionTokem = this.state.sessionToken;
    Backend.addNewForm(sessionTokem, this.state.boardId).then((forms) => {
      this.setState({ addingNewForm: false, forms: forms });
    });
  };

  editForm = (formId) => {
    this.setState({ loading: true });
    let sessionTokem = this.state.sessionToken;
    Backend.getForm(sessionTokem, formId).then((form) => {
      //console.log(board);
      this.setState({ loading: false, form: form });
    });
  };

  getContext = (res) => {
    const context = res.data;
    this.setState({ context, boardId: context.boardId }, function () {
      this.getForms();
    });
  };

  sectionNameChanged = (val, index) => {
    let form = { ...this.state.form };
    form.fields[index].sectionName = val;
    this.setState({ form: form });
  };

  sectionDescriptionChanged = (val, index) => {
    let form = { ...this.state.form };
    form.fields[index].sectionDescription = val;
    this.setState({ form: form });
  };

  displayNameChanged = (val, index) => {
    let form = { ...this.state.form };
    form.fields[index].displayName = val;
    this.setState({ form: form });
  };

  fieldDescriptionChanged = (val, index) => {
    let form = { ...this.state.form };
    form.fields[index].description = val;
    this.setState({ form: form });
  };

  titleChanged = (val) => {
    let form = { ...this.state.form };
    form.title = val;
    this.setState({ form: form });
  };

  nameChanged = (val) => {
    let form = { ...this.state.form };
    form.name = val;
    this.setState({ form: form });
  };

  descriptionChanged = (val) => {
    let form = { ...this.state.form };
    form.description = val;
    this.setState({ form: form });
  };

  successTitleChanged = (val) => {
    let form = { ...this.state.form };
    form.success_title = val;
    this.setState({ form: form });
  };

  successDescriptionChanged = (val) => {
    let form = { ...this.state.form };
    form.success_description = val;
    this.setState({ form: form });
  };

  logoShowChanged = (val) => {
    let form = { ...this.state.form };
    form.show_logo = val.target.checked;
    this.setState({ form: form });
  };

  formFieldChanged = (e) => {
    let form = { ...this.state.form };
    if (e.target.checked) {
      if (e.target.name === 'signature') {
        form.fields[e.target.value]['upload'] = false;
      } else if (e.target.name === 'upload') {
        form.fields[e.target.value]['signature'] = false;
      }
    }
    form.fields[e.target.value][e.target.name] = e.target.checked;
    this.setState({ form: form });
  };

  saveForm = () => {
    this.setState({ formSaving: true });
    Backend.saveForm(this.state.sessionToken, this.state.form).then((form) => {
      this.setState({ formSaving: false, form: null });
      monday.execute('notice', {
        message: 'Your form configuration has been saved.',
        type: 'success',
        timeout: 10000,
      });
    });
  };

  refreshForm = () => {
    this.setState({ formRefreshing: true });
    Backend.refreshForm(this.state.sessionToken, this.state.form.id).then(
      (form) => {
        this.setState({ formRefreshing: false, form: form });
      }
    );
  };

  changeFormStatus = (formId, enabled) => {
    this.setState({ formStatusChanging: formId });
    Backend.changeFormStatus(this.state.sessionToken, formId, enabled).then(
      (forms) => {
        this.setState({ formStatusChanging: false, forms: forms });
      }
    );
  };

  deleteForm = (formId) => {
    monday
      .execute('confirm', {
        message:
          'You are about to delete the selected form. This action is not reversible. Do you want to proceed?',
        confirmButton: 'Yes, delete it!',
        cancelButton: 'Cancel',
        excludeCancelButton: false,
      })
      .then((res) => {
        if (res.data.confirm) {
          this.setState({ formDeleting: formId });
          Backend.deleteForm(this.state.sessionToken, formId).then((forms) => {
            this.setState({ formDeleting: false, forms: forms });
          });
        }
      });
  };

  onDragEnd = (e) => {
    //console.log(e);
    if (!e.destination) {
      return;
    }
    let form = { ...this.state.form };
    const sorted = this.reorder(
      form.fields,
      e.source.index,
      e.destination.index
    );
    form.fields = sorted;
    this.setState({ form: form });
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  addSectionHeader = () => {
    let form = { ...this.state.form };
    form.fields.unshift({
      isSection: true,
    });
    this.setState({ form: form });
  };

  render() {
    if (this.state.loading) {
      return (
        <div>
          <div className="centered">
            <Loader size={40} />
          </div>
        </div>
      );
    }

    if (this.state.form) {
      return (
        <div style={{ width: '100%' }}>
          <div
            style={{
              maxWidth: '400px',
              marginBottom: 'var(--spacing-medium)',
            }}>
            <TextField
              placeholder="Form name"
              type="text"
              value={this.state.form.name ?? ''}
              onChange={this.nameChanged}
              wrapperClassName="monday-storybook-text-field_size"
              title="Form name"
            />
          </div>

          <Flex
            justify={Flex.justify.SPACE_BETWEEN}
            style={{ width: '100%' }}
            gap={Flex.gaps.SMALL}>
            <h2>Fields, Settings and Permissions</h2>
            <Button
              kind={Button.kinds.SECONDARY}
              onClick={this.addSectionHeader}
              rightIcon={AddNewDoc}>
              Add Section Header
            </Button>
            <Button
              kind={Button.kinds.SECONDARY}
              onClick={this.refreshForm}
              loading={this.state.formRefreshing}
              rightIcon={Retry}>
              Refresh Board
            </Button>
          </Flex>

          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="Table">
              {(provided) => (
                <table {...provided.droppableProps} ref={provided.innerRef}>
                  <tbody>
                    {this.state.form.fields.map((item, index) => {
                      return (
                        <Draggable
                          key={index}
                          draggableId={index.toString()}
                          index={index}>
                          {(provided) => (
                            <>
                              {item.isSection ?? false ? (
                                <tr
                                  key={index}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}>
                                  <td
                                    key="section"
                                    {...provided.dragHandleProps}
                                    className="dragMove">
                                    <b>Section</b>
                                  </td>
                                  <td key="name" colSpan={2}>
                                    <TextField
                                      placeholder="Section name"
                                      type="text"
                                      value={item.sectionName}
                                      onChange={(val) =>
                                        this.sectionNameChanged(val, index)
                                      }
                                      wrapperClassName="monday-storybook-text-field_size"
                                    />
                                  </td>
                                  <td key="description" colSpan={4}>
                                    <TextField
                                      placeholder="Section description"
                                      type="text"
                                      value={item.sectionDescription ?? ''}
                                      onChange={(val) =>
                                        this.sectionDescriptionChanged(
                                          val,
                                          index
                                        )
                                      }
                                      wrapperClassName="monday-storybook-text-field_size"
                                    />
                                  </td>
                                </tr>
                              ) : (
                                <tr
                                  key={index}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}>
                                  <td
                                    key="name"
                                    {...provided.dragHandleProps}
                                    className="dragMove">
                                    {item.title}
                                  </td>
                                  <td key="displayName">
                                    <TextField
                                      placeholder="Enter the display name"
                                      type="text"
                                      value={item.displayName}
                                      onChange={(val) =>
                                        this.displayNameChanged(val, index)
                                      }
                                      wrapperClassName="monday-storybook-text-field_size"
                                    />
                                  </td>
                                  <td key="description">
                                    <TextField
                                      placeholder="Enter a description"
                                      type="text"
                                      value={item.description ?? ''}
                                      onChange={(val) =>
                                        this.fieldDescriptionChanged(val, index)
                                      }
                                      wrapperClassName="monday-storybook-text-field_size"
                                    />
                                  </td>
                                  <td key="visible">
                                    <Checkbox
                                      checked={item.visible}
                                      onChange={this.formFieldChanged}
                                      name="visible"
                                      value={index.toString()}
                                      label="Visible"
                                    />
                                  </td>
                                  <td key="enabled">
                                    <Checkbox
                                      checked={item.enabled}
                                      disabled={!item.visible}
                                      label="Editable"
                                      onChange={this.formFieldChanged}
                                      name="enabled"
                                      value={index.toString()}
                                    />
                                  </td>
                                  <td key="required">
                                    <Checkbox
                                      checked={item.required}
                                      disabled={!item.visible || !item.enabled}
                                      label="Required"
                                      onChange={this.formFieldChanged}
                                      name="required"
                                      value={index.toString()}
                                    />
                                  </td>
                                  <td key="extra">
                                    {item.type === 'file' && (
                                      <Checkbox
                                        checked={item.signature ?? false}
                                        disabled={
                                          !item.visible || !item.enabled
                                        }
                                        name="signature"
                                        onChange={this.formFieldChanged}
                                        value={index.toString()}
                                        label="Signature"
                                      />
                                    )}
                                    {item.type === 'file' && (
                                      <Checkbox
                                        checked={item.upload ?? false}
                                        disabled={
                                          !item.visible || !item.enabled
                                        }
                                        name="upload"
                                        onChange={this.formFieldChanged}
                                        value={index.toString()}
                                        label="Upload files"
                                      />
                                    )}
                                    {item.type === 'dropdown' &&
                                      Object.entries(
                                        item.settings['labels'] ?? []
                                      ).map(([key, ele]) => {
                                        return <p key={key}>{ele.name}</p>;
                                      })}
                                    {item.type === 'color' &&
                                      Object.entries(
                                        item.settings['labels'] ?? []
                                      ).map(([key, ele]) => {
                                        return <p key={key}>{ele}</p>;
                                      })}
                                  </td>
                                </tr>
                              )}
                            </>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </tbody>
                </table>
              )}
            </Droppable>
          </DragDropContext>

          <h2>Permissions</h2>
          <div style={{ marginBottom: 'var(--spacing-medium)' }}>
            <Flex>
              <p>Form permissions : </p>
              <div style={{ width: '200px', marginLeft: '8px' }}>
                <Dropdown
                  className="dropdown-stories-styles_spacing"
                  placeholderClassName="placeholder"
                  value={this.state.form.access_level ?? 'user_level'}
                  options={[
                    {
                      value: 'user_level',
                      label: 'User Level',
                    },
                    {
                      value: 'account_level',
                      label: 'Account Level',
                    },
                  ]}
                  onChange={(e) => {
                    let form = { ...this.state.form };
                    form.access_level = e.value;
                    this.setState({ form: form });
                  }}
                />
              </div>
            </Flex>
          </div>

          <h2>Display settings</h2>

          <div
            style={{
              maxWidth: '400px',
              marginBottom: 'var(--spacing-medium)',
            }}>
            <Checkbox
              label="Show company logo at the top"
              checked={Boolean(this.state.form.show_logo ?? false)}
              onChange={this.logoShowChanged}
            />

            <div style={{ marginBottom: 'var(--spacing-small)' }}></div>

            <TextField
              placeholder="Add a title to the form"
              type="text"
              value={this.state.form.title ?? ''}
              onChange={this.titleChanged}
              wrapperClassName="monday-storybook-text-field_size"
              title="Title"
            />

            <div style={{ marginBottom: 'var(--spacing-small)' }}></div>

            <TextField
              placeholder="Add form description here"
              type="text"
              value={this.state.form.description ?? ''}
              onChange={this.descriptionChanged}
              wrapperClassName="monday-storybook-text-field_size"
              title="Description"
            />

            <div style={{ marginBottom: 'var(--spacing-small)' }}></div>

            <TextField
              placeholder="The title of the success message"
              type="text"
              value={this.state.form.success_title}
              onChange={this.successTitleChanged}
              wrapperClassName="monday-storybook-text-field_size"
              title="Success Title after form is submitted"
            />

            <div style={{ marginBottom: 'var(--spacing-small)' }}></div>

            <TextField
              placeholder="The description of the success message"
              type="text"
              value={this.state.form.success_description}
              onChange={this.successDescriptionChanged}
              wrapperClassName="monday-storybook-text-field_size"
              title="Success Message"
            />
          </div>

          <h2>Actions after submission</h2>

          <div style={{ marginBottom: 'var(--spacing-medium)' }}>
            <Flex>
              <p>When the form is submitted, change the value of column</p>
              <div style={{ width: '200px', marginLeft: '8px' }}>
                <Dropdown
                  className="dropdown-stories-styles_spacing"
                  placeholderClassName="placeholder"
                  options={this.state.form.fields
                    .map((item, index) => {
                      if (item.type !== 'color') return null;
                      return { label: item.title, value: index };
                    })
                    .filter((item) => item !== null)}
                  onChange={(e) => {
                    let form = { ...this.state.form };
                    form.submitStatusChangeField =
                      this.state.form.fields[e.value].id;
                    form.submitStatusChangeFieldValue = null;
                    this.setState({ form: form });
                  }}
                  value={
                    this.state.form.fields.find(
                      (e) => e.id === this.state.form.submitStatusChangeField
                    )?.title
                  }
                  placeholder="Choose column"
                />
              </div>
              {this.state.form.submitStatusChangeField && (
                <div style={{ marginLeft: '8px' }}>to</div>
              )}
              {this.state.form.submitStatusChangeField && (
                <div style={{ width: '200px', marginLeft: '8px' }}>
                  <Dropdown
                    className="dropdown-stories-styles_spacing"
                    placeholderClassName="placeholder"
                    onChange={(e) => {
                      let form = { ...this.state.form };
                      form.submitStatusChangeFieldValue = e.label;
                      this.setState({ form: form });
                    }}
                    options={Object.entries(
                      this.state.form.fields.find(
                        (e) => e.id === this.state.form.submitStatusChangeField
                      )?.settings.labels ?? []
                    ).map(([index, item]) => {
                      return { label: item, value: index };
                    })}
                    placeholder="Select a status"
                    value={this.state.form.submitStatusChangeFieldValue}
                  />
                </div>
              )}
            </Flex>
          </div>

          <div style={{ marginBottom: 'var(--spacing-xxxl)' }}>
            <Flex>
              <p>Deactivate the form link when the value of column</p>
              <div style={{ width: '200px', marginLeft: '8px' }}>
                <Dropdown
                  className="dropdown-stories-styles_spacing"
                  placeholderClassName="placeholder"
                  onChange={(e) => {
                    let form = { ...this.state.form };
                    form.deactivationStatusField =
                      this.state.form.fields[e.value].id;
                    form.deactivationStatusFieldValue = null;
                    this.setState({ form: form });
                  }}
                  options={this.state.form.fields
                    .map((item, index) => {
                      if (item.type !== 'color') return null;
                      return { label: item.title, value: index };
                    })
                    .filter((item) => item !== null)}
                  placeholder="Choose column"
                  value={
                    this.state.form.fields.find(
                      (e) => e.id === this.state.form.deactivationStatusField
                    )?.title
                  }
                />
              </div>
              {this.state.form.deactivationStatusField && (
                <div style={{ marginLeft: '8px' }}>is</div>
              )}
              {this.state.form.deactivationStatusField && (
                <div style={{ width: '250px', marginLeft: '8px' }}>
                  <Dropdown
                    className="dropdown-stories-styles_spacing"
                    placeholderClassName="placeholder"
                    onChange={(e) => {
                      let form = { ...this.state.form };
                      form.deactivationStatusFieldValue = e.label;
                      this.setState({ form: form });
                    }}
                    options={Object.entries(
                      this.state.form.fields.find(
                        (e) => e.id === this.state.form.deactivationStatusField
                      )?.settings.labels ?? []
                    ).map(([index, item]) => {
                      return { label: item, value: index };
                    })}
                    placeholder="Select the deactivation value"
                    value={this.state.form.deactivationStatusFieldValue}
                  />
                </div>
              )}
            </Flex>
          </div>

          <div style={{ position: 'fixed', right: '10%', bottom: '20px' }}>
            <Button
              onClick={() => {
                this.setState({ form: null });
              }}
              kind={Button.kinds.SECONDARY}
              style={{
                backgroundColor: 'white',
                marginRight: 'var(--spacing-medium)',
              }}>
              Back
            </Button>
            <Button onClick={this.saveForm} loading={this.state.formSaving}>
              Save
            </Button>
          </div>
        </div>
      );
    }

    if (this.state.forms) {
      return (
        <div>
          <h2>Forms</h2>
          {this.state.forms.length === 0 && (
            <div style={{ marginBottom: 'var(--spacing-medium)' }}>
              You haven't created any forms yet.
            </div>
          )}

          {this.state.forms.length > 0 && (
            <table style={{ marginBottom: 'var(--spacing-medium)' }}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Permissions</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.forms.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>
                        {item.access_level == 'user_level' ? 'User' : 'Account'}
                      </td>
                      <td>
                        {item.status === 'enabled' ? 'Enabled' : 'Disabled'}
                      </td>
                      <td>
                        <Flex>
                          <Button
                            onClick={() => {
                              this.editForm(item.id);
                            }}
                            kind={Button.kinds.SECONDARY}
                            size={Button.sizes.SMALL}
                            style={{ marginRight: 'var(--spacing-medium)' }}>
                            Edit Form
                          </Button>
                          {item.status === 'enabled' ? (
                            <Button
                              onClick={() => {
                                this.changeFormStatus(item.id, false);
                              }}
                              kind={Button.kinds.SECONDARY}
                              size={Button.sizes.SMALL}
                              color={Button.colors.NEGATIVE}
                              loading={
                                this.state.formStatusChanging === item.id
                              }>
                              Disable
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                this.changeFormStatus(item.id, true);
                              }}
                              kind={Button.kinds.SECONDARY}
                              size={Button.sizes.SMALL}
                              color={Button.colors.POSITIVE}
                              loading={
                                this.state.formStatusChanging === item.id
                              }>
                              Enable
                            </Button>
                          )}
                          <Button
                            onClick={() => {
                              this.deleteForm(item.id);
                            }}
                            kind={Button.kinds.SECONDARY}
                            size={Button.sizes.SMALL}
                            color={Button.colors.NEGATIVE}
                            loading={this.state.formDeleting === item.id}
                            style={{ marginLeft: 'var(--spacing-medium)' }}>
                            Delete
                          </Button>
                        </Flex>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          <Button onClick={this.addNewForm} loading={this.state.addingNewForm}>
            Add New Form
          </Button>

          <div style={{ marginTop: 'var(--spacing-medium)' }}>
            <p>Quick Instructions:</p>
            <ol>
              <li>Add New Form</li>
              <li>Edit Form: to configure the form</li>
              <li>Disable: disable the form</li>
              <li>Enable: enable the form</li>
              <li>Delete: delete the form</li>
            </ol>

            <p>
              For more details how to configure the form:{' '}
              <a
                rel="noreferrer"
                target="_blank"
                href="https://view.monday.com/3995300148-7e1b1b55c0f8b287bb96b1204f209270?r=use1">
                User guide
              </a>
            </p>

            <p>Note: Please refresh the board every time the form was edited</p>

            <p>
              How to create shareable links:{' '}
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.dropbox.com/scl/fi/c50aalf9qf6y3mpkg4hhu/APR-How-to-generate-link.mov?rlkey=8ptvm8s0d5et853ygwkf59989&dl=0">
                Instruction video
              </a>
            </p>
          </div>
        </div>
      );
    }
  }
}

export default MainForm;
