import { Text } from 'monday-ui-react-core';

export default function OperationCounterForm({ linkOC }) {
  //   const appContext = useContext(AppContext);
  //   const [loading, setLoading] = useState(true);

  //   useEffect(() => {
  //     const intervalId = setInterval(() => {
  //       if (linkOC?.length) {
  //         setLoading(false);
  //         clearInterval(intervalId);
  //       }
  //     }, 10); // Check every 10ms

  //     const timeoutId = setTimeout(() => {
  //       setLoading(false);
  //       clearInterval(intervalId);
  //     }, 3000); // Stop loading after 3 seconds

  //     return () => {
  //       clearInterval(intervalId);
  //       clearTimeout(timeoutId);
  //     };
  //   }, [linkOC]);

  //   if (loading) {
  //     return (
  //       <div className="mt-16 mx-auto w-fit">
  //         <Loader hasBackground={true} size={64} />
  //       </div>
  //     );
  //   } else

  if (!linkOC?.length) {
    return (
      <div className="w-4/5 mx-auto">
        <div>
          We cannot find your Operation Counter information. Please contact our
          support center for further assistance at{' '}
          <a href="mailto: support@b-lab.app"> support@b-lab.app </a>.
        </div>
      </div>
    );
  }

  return (
    linkOC?.length && (
      <div style={{ margin: '0 auto 3rem' }}>
        <iframe
          title="link operation counter"
          src={linkOC}
          style={{ width: '100%', height: '100vh', border: 'none' }}></iframe>
      </div>
    )
  );
}
