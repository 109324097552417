const getForm = async (sessionToken,formId) => {
    let res = await fetch("/form/get/"+formId+"/"+sessionToken);
    return await res.json();
}

const refreshForm = async (sessionToken,formId) => {
    let res = await fetch("/form/refresh/"+formId+"/"+sessionToken);
    return await res.json();
}

const saveForm = async (sessionToken,form) => {
    const data = new FormData();
    data.append('id',form.id);
    data.append('board_id',form.board_id);
    if (form.show_logo) data.append("show_logo",form.show_logo);
    if (form.name) data.append('name',form.name);
    if (form.title) data.append('title',form.title);
    if (form.description) data.append('description',form.description);
    if (form.success_title) data.append('success_title',form.success_title);
    if (form.success_description) data.append('success_description',form.success_description);
    data.append("fields", JSON.stringify(form.fields));
    if (form.submitStatusChangeField) data.append("submit_status_change_field",form.submitStatusChangeField);
    if (form.submitStatusChangeFieldValue) data.append("submit_status_change_field_value",form.submitStatusChangeFieldValue);
    if (form.deactivationStatusField) data.append("deactivation_status_field",form.deactivationStatusField);
    if (form.deactivationStatusFieldValue) data.append("deactivation_status_field_value", form.deactivationStatusFieldValue);
    if (form.access_level) data.append('access_level', form.access_level);

    let res = await fetch("/form/update/"+sessionToken,{
        method: "POST",
        body: data
    });
    return await res.json();
}

const getForms = async (sessionToken,boardId) => {
    let res = await fetch("/forms/"+boardId+"/"+sessionToken);
    return await res.json();
}

const addNewForm = async (sessionToken,boardId) => {
    const data = new FormData();
    data.append('board_id',boardId);
    let res = await fetch("/form/add/"+sessionToken,{
        method: "POST",
        body: data
    });
    return await res.json();
}

const changeFormStatus = async (sessionToken,formId,enabled) => {
    let res = await fetch("/form/status/"+formId+"/"+enabled+"/"+sessionToken);
    return await res.json();
}

const deleteForm = async (sessionToken,formId) => {
    let res = await fetch("/form/delete/"+formId+"/"+sessionToken);
    return await res.json();
}

const getOperationCounterLink = async (accountId) => {
  let res = await fetch('/operation-counter-link/' + accountId);
  return await res;
};

export {
  getForm,
  saveForm,
  refreshForm,
  getForms,
  addNewForm,
  changeFormStatus,
  deleteForm,
  getOperationCounterLink,
};